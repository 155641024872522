// Common labels for the site
module.exports = {
  global: {
    copyright: "Cornell Tech. All right reserved",
  },
  trends: {
    chart_title: "How likely? How soon? What impact?",
    metadata_section_heading: "Key Dimensions",
  },
}
